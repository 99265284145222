@import "custom";
@import "~bootstrap/scss/bootstrap";

// datetimepicker
@import "~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4-build";

// compact nav on md or smaller
.input_nav .nav-link { padding: 0.5rem; }
@include media-breakpoint-up('lg') {
	.input_nav .nav-link { padding: 0.5rem 0.7rem; }
}

// add asterisk to required fields title
.col-form-label.required:after {
	content: "*";
	color: #b90000;
}

.was-validated .form-group .form-check-input:last-child:invalid ~.form-check-label:after{
	content: "Jāaizpilda obligāti";
	color: #b90000;
}

